@media (max-width: 768px) {
  #sales {
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  #sales {
    overflow: hidden;
  }
}
