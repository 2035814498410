.body {
    margin: 0;
    padding: 0;
    background-color: #0055fe;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    font-family: 'Nirmala', sans-serif, Tahoma;
}

.header {
    width: 95%;
    max-width: 788px;
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    margin-top: 15px;
}

.share-button {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #fff;
}

.share-button svg {
    margin-left: 11px;
    margin-top: 11px;
    color: rgb(0, 0, 0);
}

.container {
  width: 91%;
  max-width: 680px;
  margin: 10px 0 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container h1 {
  margin: 30px 0;
  font-weight: 500;
  font-size: 36px;
  font-family: 'Nirmala', sans-serif;
  font-weight: bold;
}

a {
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Nirmala', sans-serif;
}

.tile {
    width: 40%;
    background-color: #4ed44f;
    margin: 7px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 3px 3px 0 0 #034ddf;
}

.tile p {
  color: #fff;
  text-align: center;
  margin: auto auto;
  font-weight: bold;
  font-family: 'Nirmala', sans-serif;
  font-size: 18px;
}

.tile:hover {
    transition: cubic-bezier(0.7, 1.41, .82, 1.41) 0.2s;
    transform: scale(1.02);
}

.tile-share-button {
    margin: 8px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #034ddf;
}

.tile-share-button svg {
    margin-left: 11px;
    margin-top: 11px;
}

.image-container {
  height: 125px;
  width: 125px;
  border-radius: 48px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
    height: 65%;
    width: auto;
    display: block;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-65%);
}

.icon {
    margin: 4px 12px;
    width: 30px;
    height: 30px;
}

.alert {
    font-weight: 500;
    font-size: 20px;
    font-family: 'Nirmala', sans-serif;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #4ed44f;
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: fadeInOut 3s ease-in-out;
  }

  @keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    10%, 90% { opacity: 1; }
  }

.footer {
    background-color: #0055fe;
    border: 3px solid #4ed44f;
    width: 100%;
    position: fixed;
    bottom: 0;
}

.footer .direitos-reservados {
    border: none;
    font-size: 14px;
    font-family: 'Nirmala', sans-serif;
    margin: 10px 0;
    text-align: center;
    color: #4ed44f;
    font-weight: bold;
}

@media (max-width: 768px) {
  .tile {
    width: 55%;
    padding: 12px;
    margin: 12px 0;
  }
}

@media (max-width: 480px) {
  .tile {
    width: 70%;
    padding: 5px;
    margin: 5px 0;
  }

  a {
      margin: 0 auto;
  }
}
