@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nirmala';
  src: url('./assets/fonts/Nirmala.ttf') format('opentype');
}

@font-face {
  font-family: 'MyriadPro';
  src: url('./assets/fonts/myriadProRegular.OTF') format('opentype');
}

@font-face {
  font-family: 'AcuminPro';
  src: url('./assets/fonts/AcuminProExtraCond.otf') format('opentype');
}

@font-face {
  font-family: 'AcuminPro';
  src: url('./assets/fonts/AcuminProExtraCond.otf') format('opentype');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

.nirmala-font {
  font-family: 'Nirmala', sans-serif;
}

.MyriadPro-font {
  font-family: 'MyriadPro', sans-serif;
}

.AcuminPro-font {
  font-family: 'AcuminPro', sans-serif;
}

.roboto-flex-font {
  font-family: 'Roboto Flex', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

body {
  margin: 0;
  background-color: #0055fe;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#0055fe, #90ff3c);
  border-radius: 6px;
}

::-moz-scrollbar {
  width: 10px;
}

::-moz-scrollbar-thumb {
  background: linear-gradient(#0055fe, #90ff3c);
  border-radius: 6px;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;

}
input[type=number] {
 -moz-appearance: textfield;
 appearance: textfield;

}

@media (max-width: 768px) {
  .home {
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  .home {
    overflow: hidden;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

